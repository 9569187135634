module.exports = [{
      plugin: require('../node_modules/_gatsby-remark-autolink-headers@2.3.11@gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/_gatsby-plugin-manifest@2.4.20@gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-theme-antv","short_name":"gatsby-starter-theme-antv","start_url":"/","background_color":"#873bf4","theme_color":"#873bf4","display":"minimal-ui","icon":"/Users/afc163/Projects/gatsby-starter-theme-antv/node_modules/_@antv_gatsby-theme-antv@0.10.69@@antv/gatsby-theme-antv/site/images/favicon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"9772447a8d07a8fe19894b5176c6cb0d"},
    },{
      plugin: require('../node_modules/_gatsby-plugin-catch-links@2.3.11@gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/_gatsby-plugin-nprogress@2.3.10@gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/_gatsby-plugin-layout@1.3.10@gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/Users/afc163/Projects/gatsby-starter-theme-antv/node_modules/_@antv_gatsby-theme-antv@0.10.69@@antv/gatsby-theme-antv/site/layouts/layout.tsx"},
    },{
      plugin: require('../node_modules/_gatsby-plugin-nprogress@2.3.10@gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#873bf4"},
    },{
      plugin: require('../node_modules/_@antv_gatsby-theme-antv@0.10.69@@antv/gatsby-theme-antv/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
